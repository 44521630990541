import React from "react"
import PropTypes from "prop-types"

const StepHeader = ({ text = "" }) => (
  <h1 className="font-sans mt-5 2xl:mt-10 text-2xl lg:text-4xl 2xl:text-6xl">
    {text}
  </h1>
)

StepHeader.propTypes = {
  text: PropTypes.string,
}

export default StepHeader
