import React from "react"

type ButtonProps = {
  text: string
  onClick: () => void
  className: string
  style: Object
}
const PrimaryButton = ({ text, onClick, className, style }: ButtonProps) => (
  <button
    className={"button button__primary " + className}
    onClick={onClick}
    style={style}
  >
    {text}
  </button>
)

const SecondaryButton = ({ text, onClick, className, style }: ButtonProps) => (
  <button
    className={"button button__secondary " + className}
    onClick={onClick}
    style={style}
  >
    {text}
  </button>
)

export { PrimaryButton, SecondaryButton }
