import React from "react"

type InputFieldProps = {
  label: string
  placeholder: string
  style: Object
  value: string
  onChange: () => void
}

const InputField = ({
  label,
  style,
  placeholder,
  onChange,
  value,
}: InputFieldProps) => {
  return (
    <div>
      <div className="mb-1">{label}</div>
      <input
        type="text"
        style={style}
        className="px-4 py-3 w-full border border-solid border-black"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  )
}

export default InputField
