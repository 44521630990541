import React from "react"
import PropTypes from "prop-types"
type Props = {
  text: string
  className: string
}

const Tooltip = ({ text = "", className }: Props) => (
  <div
    className={`i tc f7 mh2 br-100 bg-light-gray w1 h1 flex items-center justify-center tooltip ${className}`}
  >
    <span>i</span>
    <span className="tooltiptext">{text}</span>
  </div>
)

Tooltip.propTypes = {
  text: PropTypes.string,
}

export default Tooltip
