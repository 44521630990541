import React, { useMemo, useState } from "react"
import AddOnSelect from "../AddOnSelect"
import { ConnectorType } from "../utils/types"
import attentionImage from "images/attention.svg"
import { connect } from "react-redux"
import { filterIncompatiblePlugs } from "../../helpers/IncompatibilityHelper"

type Props = {
  id: number
  name: string
  image_url: string
  onSelect?: (bottomCableId: number, plugId: number) => void
  className?: string
  description?: string
  addOnName: string
  cartIds: number[]
  plugs: ConnectorType[] | undefined
  incompatibilities: any
  cableIdName: "bottomCableId" | "cableWithoutPttId"
}

const CableCardWithDropdown = (props: Props) => {
  const {
    id,
    name,
    image_url,
    onSelect,
    className,
    description,
    addOnName,
    plugs,
    incompatibilities,
    cartIds,
    cableIdName,
  } = props

  const [selectedConnector, setSelectedConnector] = useState<number>(0)

  if (plugs === undefined) return

  const handleSelect = (plugId: number) => {
    onSelect && onSelect(id, plugId)
  }

  const compatiblePlugs = useMemo(() => {
    const filtered = plugs.filter((plug: ConnectorType) => {
      const incompatiblePlugs = filterIncompatiblePlugs(
        plugs,
        incompatibilities,
        // Pass in this card's id to check its compatibility with the top cables
        { ...cartIds, [cableIdName]: id },
      )

      const isCompatible =
        incompatiblePlugs.find(
          (incompatiblePlug: any) => incompatiblePlug.id === plug.id,
          // We did not find any incompatibility
        ) === undefined

      return isCompatible
    })
    return filtered
  }, [
    JSON.stringify(plugs),
    JSON.stringify(incompatibilities),
    JSON.stringify(cartIds),
  ])

  return (
    <article
      className={`group flex flex-col w-full border-b-2 border-slate-100 ${className} cursor-pointer`}
    >
      <div className="relative flex justify-center h-96 bg-primaryGray">
        {onSelect !== undefined && (
          <div className="group-hover:bg-hoverGreen opacity-80 absolute top-0 left-0 h-full w-full flex flex-col gap-4 justify-center items-center">
            <p className="opacity-0 group-hover:opacity-100 text-white flex items-center gap-2 px-4">
              <img src={attentionImage} className="w-14" />
              <span>Select a {addOnName} from the dropdown below</span>
            </p>
          </div>
        )}
        <img src={image_url} alt={name} className="p-2 max-h-full" />
      </div>
      <div className="py-5">
        <p className="text-lg font-semibold mb-2 lg:mb-4">{name}</p>
        <pre className="font-sans font-light text-sm">{description}</pre>
      </div>
      <AddOnSelect
        addOn={compatiblePlugs}
        className="plug-select mb-5"
        onAddOnSelect={handleSelect}
        selectedAddOn={selectedConnector}
        setSelectedAddOn={setSelectedConnector}
        optionName="Choose connector"
      />
    </article>
  )
}

const mapStateToProps = (state: any) => ({
  cartIds: state.cartIds,
  plugs: state.plugs.items,
  incompatibilities: state.incompatibilities.items,
})

export default connect(mapStateToProps)(CableCardWithDropdown)
