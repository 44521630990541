import React, { useState, useEffect } from "react"
import "./style.scss"

type ProgressBarType = {
  numberOfSteps: number
  activeStep: number
  title?: string
}

const ProgressBar = ({ numberOfSteps, activeStep, title }: ProgressBarType) => {
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    setWidth((activeStep / numberOfSteps) * 100)
  }, [numberOfSteps, activeStep])

  return (
    <div className="w-full mx-auto mt-4">
      <div className="progressBar">
        <div
          className="progressBar__progress"
          style={{ width: width + "%" }}
        ></div>
      </div>
      {title && (
        <div className="flex w-full">
          {/* <div style={{ width: 100 - width + "%" }}></div> */}
          <div
            className="mt-2 ml-2 font-sans font-thin text-xs md:text-base text-right pr-2"
            style={{ width: width + "%" }}
          >
            {title}
          </div>
        </div>
      )}
    </div>
  )
}

export default ProgressBar
