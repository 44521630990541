import React from "react"
import { Order } from "../OrderDetails"

type OrderDetailsVisualProps = {
  order: Order[]
}

const OrderDetailsVisual = ({ order }: OrderDetailsVisualProps) => {
  const plugConfigurationObject = order.find(
    (item: { title: string }) => item.title === "Plug Configuration",
  )

  let string = plugConfigurationObject?.content.replace(
    "resistorValue",
    "Resistor Value",
  )
  string = string?.replace("additionalRequirements", "Comments")

  const arrayToJoin = string?.split("* ").slice(1)

  return (
    <div className="flex flex-col gap-5 lg:grid lg:grid-cols-2 2xl:grid-cols-3">
      {order.map((value: Order, index: number) => (
        <div
          key={index}
          className="flex gap-5 items-center border-b-2 lg:border-b-0 py-2 lg:flex-col"
        >
          <div className="w-20 lg:w-full lg:h-48 flex items-center justify-center max-w-64 overflow-hidden border-2 border-gray-100 bg-primaryGray">
            <img src={value.image_url} />
          </div>
          {value.title == "Plug Configuration" ? (
            <div>
              {arrayToJoin &&
                arrayToJoin.map((item: any) => (
                  <p key={item.substring(3)}>{item}</p>
                ))}
            </div>
          ) : (
            <div>{value.name}</div>
          )}
        </div>
      ))}
    </div>
  )
}

export default OrderDetailsVisual
