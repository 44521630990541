import { useState, useEffect } from "react"

const useWindowWidth = () => {
  const [width, setWidth] = useState<number>(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768
  const isScreenSmaller = width <= 1024

  return { width, isMobile, isScreenSmaller }
}

export default useWindowWidth
