import React from "react"
import smallArrow from "images/small-arrow.svg"
import useWindowWidth from "hooks/useWindowWidth"
import { useSelector } from "react-redux"
import { getCart } from "../../cartSelector"
import { getFooterArray } from "../../utils/getFooterArray"

type ProgressFooterProps = {
  skipStep?: () => void
  backStep?: () => void
  skipStepText?: String
}

const ProgressFooter = ({
  skipStep,
  backStep,
  skipStepText,
}: ProgressFooterProps) => {
  const { isMobile, isScreenSmaller } = useWindowWidth()

  const cartObject = useSelector((state: any) => getCart(state))

  const [variant, ...sections] = getFooterArray(cartObject)

  const Section = ({ section }: any) => {
    if (!section) return <div></div>

    return (
      <div className="border-l border-white ml-3 pl-3 font-thin">{section}</div>
    )
  }

  return (
    <div className="font-sans fixed w-100 bg-black text-white bottom-0 right-0 h-16 print:hidden">
      <div
        onClick={backStep}
        className="flex h-100 bg-white w-16 absolute left-0 bottom-0 text-black border border-black cursor-pointer"
      >
        <img src={smallArrow} className="m-auto" />
      </div>

      <div className="flex text-white absolute left-32 bottom-0 h-full">
        <div className="my-auto flex">
          {isScreenSmaller ? (
            <div className="ml-2 flex items-center">
              <span>...</span>
              <Section section={sections.slice(-1)} />
            </div>
          ) : (
            <div className="flex items-center">
              {variant}
              {sections.map((section: String, index: number) => (
                <Section section={section} key={index} />
              ))}
            </div>
          )}
        </div>
      </div>

      {skipStep && (
        <>
          {isMobile ? (
            <div
              onClick={skipStep}
              className="flex h-100 bg-white w-16 absolute right-0 bottom-0 text-black border border-black cursor-pointer"
            >
              <img src={smallArrow} className="m-auto rotate-180" />
            </div>
          ) : (
            <div
              onClick={skipStep}
              className="flex absolute right-0 border-l top-0 h-full border-white cursor-pointer"
            >
              <div className="m-auto px-6 flex">
                {skipStepText ?? "Skip this step"}
                <div className="ml-2">&#8594;</div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ProgressFooter
