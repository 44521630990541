import React from "react"
import Logo from "images/EPOS_ikon.svg"
import LogoFull from "images/Epos.jpg"
import useWindowWidth from "hooks/useWindowWidth"

const Header = () => {
  const { isMobile } = useWindowWidth()

  return (
    <>
      {isMobile ? (
        <div className="flex items-center w-full border-b">
          <img src={Logo} />
          <div className="flex items-center w-full justify-between pr-8 pl-5">
            <div className="flex flex-col items-start text-xs">
              <span>COMMAND line</span>
              <span>headset configurator</span>
            </div>
            <a href="https://www.eposaudio.com/en">
              <p className="underline text-xs">Main site</p>
            </a>
          </div>
        </div>
      ) : (
        <div className="flex items-center w-full border-b">
          <img src={Logo} />
          <img className="h-8 my-auto ml-20" src={LogoFull} />
          <div className="flex w-full justify-between px-8 items-center">
            <p className="p-2 text-xl">COMMAND line headset configurator</p>
            <a href="https://www.eposaudio.com/en">
              <p className="underline p-2 text-sm">Back to the main site</p>
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default Header
