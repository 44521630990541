import React from "react"

// I could meta program this, but it would become hard to understand.
// The element being compared is not checked against the cartIds.
export const getIncompatiblePttSeries = ({
  pttSeries,
  incompatibilities,
  cartIds,
}) =>
  pttSeries.reduce((filtered, element) => {
    const pttIncompatibilites = elementPttSeriesIncompatibilities(
      element,
      incompatibilities,
      cartIds,
    )
    if (pttIncompatibilites.length) {
      let incompatibleElement = Object.assign({}, element, {
        message: pttIncompatibilites.map((i) => i.message).join(". "),
      })
      filtered.push(incompatibleElement)
    }
    return filtered
  }, [])

const elementPttSeriesIncompatibilities = (
  element,
  incompatibilities,
  cartIds,
) => {
  return incompatibilities.filter((incompatibility) => {
    return (
      incompatibility.ptt_series_ids.indexOf(element.id) > -1 &&
      (incompatibility.bottom_cable_ids.indexOf(cartIds.bottomCableId) > -1 ||
        incompatibility.headset_series_ids.indexOf(cartIds.headsetSeriesId) >
          -1 ||
        incompatibility.headset_ids.indexOf(cartIds.headsetId) > -1 ||
        incompatibility.plug_ids.indexOf(cartIds.plugId) > -1 ||
        incompatibility.ptt_ids.indexOf(cartIds.pttId) > -1 ||
        incompatibility.cable_without_ptt_ids.indexOf(
          cartIds.cableWithoutPttId,
        ) > -1 ||
        incompatibility.top_cable_ids.indexOf(cartIds.topCableId) > -1)
    )
  })
}

export const filterIncompatiblePtts = ({ ptts, incompatibilities, cartIds }) =>
  ptts.reduce((filtered, element) => {
    const pttIncompatibilites = elementPttIncompatibilities(
      element,
      incompatibilities,
      cartIds,
    )
    if (pttIncompatibilites.length) {
      let incompatibleElement = Object.assign({}, element, {
        message: pttIncompatibilites.map((i) => i.message).join(". "),
      })
      filtered.push(incompatibleElement)
    }
    return filtered
  }, [])

export const elementPttIncompatibilities = (
  element,
  incompatibilities,
  cartIds,
) => {
  return incompatibilities.filter((incompatibility) => {
    return (
      incompatibility.ptt_ids.indexOf(element.id) > -1 &&
      (incompatibility.bottom_cable_ids.indexOf(cartIds.bottomCableId) > -1 ||
        incompatibility.headset_series_ids.indexOf(cartIds.headsetSeriesId) >
          -1 ||
        incompatibility.headset_ids.indexOf(cartIds.headsetId) > -1 ||
        incompatibility.plug_ids.indexOf(cartIds.plugId) > -1 ||
        incompatibility.ptt_series_ids.indexOf(cartIds.pttSeriesId) > -1 ||
        incompatibility.cable_without_ptt_ids.indexOf(
          cartIds.cableWithoutPttId,
        ) > -1 ||
        incompatibility.top_cable_ids.indexOf(cartIds.topCableId) > 1)
    )
  })
}

export const filterIncompatibleBottomCables = (
  list,
  incompatibilities,
  cartIds,
) => {
  return list.reduce((filtered, element) => {
    const bottomCableIncompatibilites = elementBottomCableIncompatibilities(
      element,
      incompatibilities,
      cartIds,
    )
    if (bottomCableIncompatibilites.length) {
      let incompatibleElement = Object.assign({}, element, {
        message: bottomCableIncompatibilites.map((i) => i.message).join(". "),
      })
      filtered.push(incompatibleElement)
    }
    return filtered
  }, [])
}

const elementBottomCableIncompatibilities = (
  element,
  incompatibilities,
  cartIds,
  cableId,
) => {
  return incompatibilities.filter((incompatibility) => {
    return (
      incompatibility.bottom_cable_ids.indexOf(element.id) > -1 &&
      (incompatibility.headset_series_ids.indexOf(cartIds.headsetSeriesId) >
        -1 ||
        incompatibility.headset_ids.indexOf(cartIds.headsetId) > -1 ||
        incompatibility.plug_ids.indexOf(cartIds.plugId) > -1 ||
        incompatibility.plug_ids.indexOf(cableId) > -1 ||
        incompatibility.ptt_ids.indexOf(cartIds.pttId) > -1 ||
        incompatibility.ptt_series_ids.indexOf(cartIds.pttSeriesId) > -1 ||
        incompatibility.cable_without_ptt_ids.indexOf(
          cartIds.cableWithoutPttId,
        ) > -1 ||
        incompatibility.top_cable_ids.indexOf(cartIds.topCableId) > -1)
    )
  })
}

export const getIncompatibleHeadsetSeries = ({
  headsetSeriess = [],
  incompatibilities,
  cartIds,
}) =>
  headsetSeriess.reduce((filtered, element) => {
    const headsetSeriesIncompatibilites = elementHeadsetSeriesIncompatibilities(
      element,
      incompatibilities,
      cartIds,
    )
    if (headsetSeriesIncompatibilites.length) {
      let incompatibleElement = Object.assign({}, element, {
        message: headsetSeriesIncompatibilites.map((i) => i.message).join(". "),
      })
      filtered.push(incompatibleElement)
    }
    return filtered
  }, [])

const elementHeadsetSeriesIncompatibilities = (
  element,
  incompatibilities,
  cartIds,
) => {
  return incompatibilities.filter((incompatibility) => {
    return (
      incompatibility.headset_series_ids.indexOf(element.id) > -1 &&
      (incompatibility.headset_ids.indexOf(cartIds.headsetId) > -1 ||
        incompatibility.bottom_cable_ids.indexOf(cartIds.bottomCableId) > -1 ||
        incompatibility.plug_ids.indexOf(cartIds.plugId) > -1 ||
        incompatibility.ptt_ids.indexOf(cartIds.pttId) > -1 ||
        incompatibility.ptt_series_ids.indexOf(cartIds.pttSeriesId) > -1 ||
        incompatibility.cable_without_ptt_ids.indexOf(
          cartIds.cableWithoutPttId,
        ) > -1 ||
        incompatibility.top_cable_ids.indexOf(cartIds.topCableId) > -1)
    )
  })
}

export const filterCompatibleHeadsets = ({
  headsets,
  incompatibilities,
  cartIds,
}) =>
  headsets.filter(
    (headset) =>
      !filterIncompatibleHeadsets({
        headsets,
        incompatibilities,
        cartIds,
      }).some((incompatibleHeadset) => incompatibleHeadset.id == headset.id),
  )

export const filterIncompatibleHeadsets = ({
  headsets,
  incompatibilities,
  cartIds,
}) => {
  return headsets.reduce((filtered, element) => {
    const headsetIncompatibilites = elementHeadsetIncompatibilities(
      element,
      incompatibilities,
      cartIds,
    )
    if (headsetIncompatibilites.length) {
      let incompatibleElement = Object.assign({}, element, {
        message: headsetIncompatibilites.map((i) => i.message).join(". "),
      })
      filtered.push(incompatibleElement)
    }
    return filtered
  }, [])
}

const elementHeadsetIncompatibilities = (
  element,
  incompatibilities,
  cartIds,
) => {
  return incompatibilities.filter((incompatibility) => {
    return (
      incompatibility.headset_ids.indexOf(element.id) > -1 &&
      (incompatibility.headset_series_ids.indexOf(cartIds.headsetSeriesId) >
        -1 ||
        incompatibility.bottom_cable_ids.indexOf(cartIds.bottomCableId) > -1 ||
        incompatibility.plug_ids.indexOf(cartIds.plugId) > -1 ||
        incompatibility.ptt_ids.indexOf(cartIds.pttId) > -1 ||
        incompatibility.ptt_series_ids.indexOf(cartIds.pttSeriesId) > -1 ||
        incompatibility.cable_without_ptt_ids.indexOf(
          cartIds.cableWithoutPttId,
        ) > -1 ||
        incompatibility.top_cable_ids.indexOf(cartIds.topCableId) > -1)
    )
  })
}

export const filterIncompatiblePlugs = (
  list,
  incompatibilities,
  cartIds,
  currentCableId,
  withPTT,
) => {
  return list.reduce((filtered, element) => {
    const plugIncompatibilites = elementPlugIncompatibilities(
      element,
      incompatibilities,
      cartIds,
      currentCableId,
      withPTT,
    )
    if (plugIncompatibilites.length) {
      let incompatibleElement = Object.assign({}, element, {
        message: plugIncompatibilites.map((i) => i.message).join(". "),
      })
      filtered.push(incompatibleElement)
    }
    return filtered
  }, [])
}

const elementPlugIncompatibilities = (
  element,
  incompatibilities,
  cartIds,
  currentCableId,
  withPTT,
) => {
  return incompatibilities.filter((incompatibility) => {
    const {
      headsetSeriesId,
      headsetId,
      bottomCableId,
      pttId,
      pttSeriesId,
      cableWithoutPttId,
      topCableId,
    } = cartIds

    return (
      incompatibility.plug_ids.indexOf(element.id) > -1 &&
      (incompatibility.headset_series_ids.indexOf(headsetSeriesId) > -1 ||
        incompatibility.headset_ids.indexOf(headsetId) > -1 ||
        incompatibility.bottom_cable_ids.indexOf(bottomCableId) > -1 ||
        (withPTT &&
          incompatibility.bottom_cable_ids.indexOf(currentCableId) > -1) ||
        incompatibility.ptt_ids.indexOf(pttId) > -1 ||
        incompatibility.ptt_series_ids.indexOf(pttSeriesId) > -1 ||
        incompatibility.cable_without_ptt_ids.indexOf(cableWithoutPttId) > -1 ||
        (!withPTT &&
          incompatibility.cable_without_ptt_ids.indexOf(cableWithoutPttId) >
            -1) ||
        incompatibility.top_cable_ids.indexOf(topCableId) > -1)
    )
  })
}

export const filterIncompatibleTopCables = (
  list,
  incompatibilities,
  cartIds,
) => {
  return list.reduce((filtered, element) => {
    const topCableIncompatibilites = elementTopCableIncompatibilities(
      element,
      incompatibilities,
      cartIds,
    )
    if (topCableIncompatibilites.length) {
      let incompatibleElement = Object.assign({}, element, {
        message: topCableIncompatibilites.map((i) => i.message).join(". "),
      })
      filtered.push(incompatibleElement)
    }
    return filtered
  }, [])
}

const elementTopCableIncompatibilities = (
  element,
  incompatibilities,
  cartIds,
) => {
  return incompatibilities.filter((incompatibility) => {
    return (
      incompatibility.top_cable_ids.indexOf(element.id) > -1 &&
      (incompatibility.headset_series_ids.indexOf(cartIds.headsetSeriesId) >
        -1 ||
        incompatibility.headset_ids.indexOf(cartIds.headsetId) > -1 ||
        incompatibility.bottom_cable_ids.indexOf(cartIds.bottomCableId) > -1 ||
        incompatibility.ptt_ids.indexOf(cartIds.pttId) > -1 ||
        incompatibility.cable_without_ptt_ids.indexOf(
          cartIds.cableWithoutPttId,
        ) > -1 ||
        incompatibility.ptt_series_ids.indexOf(cartIds.pttSeriesId) > -1)
    )
  })
}

export const filterIncompatibleCableWithoutPtts = (
  list,
  incompatibilities,
  cartIds,
) => {
  return list.reduce((filtered, element) => {
    const cableWithoutPttIncompatibilites =
      elementCableWithoutPttIncompatibilities(
        element,
        incompatibilities,
        cartIds,
      )
    if (cableWithoutPttIncompatibilites.length) {
      let incompatibleElement = Object.assign({}, element, {
        message: cableWithoutPttIncompatibilites
          .map((i) => i.message)
          .join(". "),
      })
      filtered.push(incompatibleElement)
    }
    return filtered
  }, [])
}

const elementCableWithoutPttIncompatibilities = (
  element,
  incompatibilities,
  cartIds,
) => {
  return incompatibilities.filter((incompatibility) => {
    return (
      incompatibility.cable_without_ptt_ids.indexOf(element.id) > -1 &&
      (incompatibility.headset_series_ids.indexOf(cartIds.headsetSeriesId) >
        -1 ||
        incompatibility.headset_ids.indexOf(cartIds.headsetId) > -1 ||
        incompatibility.bottom_cable_ids.indexOf(cartIds.bottomCableId) > -1 ||
        incompatibility.ptt_ids.indexOf(cartIds.pttId) > -1 ||
        incompatibility.top_cable_ids.indexOf(cartIds.topCableId) > -1 ||
        incompatibility.ptt_series_ids.indexOf(cartIds.pttSeriesId) > -1)
    )
  })
}
