import React from "react"

type TextAreaProps = {
  label: string
  placeholder: string
  style: Object
  value: string
  onChange: () => void
}
const TextArea = ({
  label,
  placeholder,
  style,
  value,
  onChange,
}: TextAreaProps) => {
  return (
    <div>
      <div className="mb-1">{label}</div>
      <textarea
        className="border border-solid border-black w-full h-24 resize-none px-4 py-3"
        style={style}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      ></textarea>
    </div>
  )
}

export default TextArea
