import fetch from "cross-fetch"
import { API_URL } from "./constants"

export const REQUEST_HEADSET_SERIES = "REQUEST_HEADSET_SERIES"
export const RECEIVE_HEADSET_SERIES = "RECEIVE_HEADSET_SERIES"
export const SELECT_HEADSET_SERIES = "SELECT_HEADSET_SERIES"

export const REQUEST_HEADSETS = "REQUEST_HEADSETS"
export const RECEIVE_HEADSETS = "RECEIVE_HEADSETS"
export const SELECT_HEADSET = "SELECT_HEADSET"

export const REQUEST_TOP_CABLES = "REQUEST_TOP_CABLES"
export const RECEIVE_TOP_CABLES = "RECEIVE_TOP_CABLES"
export const SELECT_TOP_CABLE = "SELECT_TOP_CABLE"

export const REQUEST_PTT_SERIES = "REQUEST_PTT_SERIES"
export const RECEIVE_PTT_SERIES = "RECEIVE_PTT_SERIES"
export const SELECT_PTT_SERIES = "SELECT_PTT_SERIES"

export const REQUEST_PTTS = "REQUEST_PTTS"
export const RECEIVE_PTTS = "RECEIVE_PTTS"
export const SELECT_PTT = "SELECT_PTT"

export const REQUEST_BOTTOM_CABLES = "REQUEST_BOTTOM_CABLES"
export const RECEIVE_BOTTOM_CABLES = "RECEIVE_BOTTOM_CABLES"
export const SELECT_BOTTOM_CABLE = "SELECT_BOTTOM_CABLE"

export const REQUEST_CABLE_WITHOUT_PTTS = "REQUEST_CABLE_WITHOUT_PTTS"
export const RECEIVE_CABLE_WITHOUT_PTTS = "RECEIVE_CABLE_WITHOUT_PTTS"
export const SELECT_CABLE_WITHOUT_PTT = "SELECT_CABLE_WITHOUT_PTT"

export const REQUEST_PLUGS = "REQUEST_PLUGS"
export const RECEIVE_PLUGS = "RECEIVE_PLUGS"
export const SELECT_PLUG = "SELECT_PLUG"

export const REQUEST_STEP_SKIPPERS = "REQUEST_STEP_SKIPPERS"
export const RECEIVE_STEP_SKIPPERS = "RECEIVE_STEP_SKIPPERS"

export const REQUEST_INCOMPATIBILITIES = "REQUEST_INCOMPATIBILITIES"
export const RECEIVE_INCOMPATIBILITIES = "RECEIVE_INCOMPATIBILITIES"

export const SET_PLUG_CONFIGURATION = "SET_PLUG_CONFIGURATION"
export const RESET_PLUG_CONFIGURATION = "RESET_PLUG_CONFIGURATION"
export const INQUIRY_SUBMITTED = "INQUIRY_SUBMITTED"

export const RESET_CART = "RESET_CART"

export const SET_HEADSET_FILTER = "SET_HEADSET_FILTER"
export const SET_PTT_FILTER = "SET_PTT_FILTER"

export const UPDATE_CUSTOMER_DETAILS = "UPDATE_CUSTOMER_DETAILS"
export const RESET_CUSTOMER_DETAILS = "RESET_CUSTOMER_DETAILS"

export const setPTTFilter = (filter) => (dispatch) => {
  dispatch({
    type: SET_PTT_FILTER,
    filter,
  })
}

export const setHeadsetFilter = (filtering) => (dispatch) => {
  dispatch({
    type: SET_HEADSET_FILTER,
    filtering,
  })
}

export const fetchHeadsetSeries = () => (dispatch) => {
  return fetch(`${API_URL}/headset_series.json`)
    .then(
      (response) => response.json(),
      (error) => console.log("An error occurred.", error),
    )
    .then((json) => dispatch(receiveHeadsetSeries(json)))
}

const receiveHeadsetSeries = (items) => ({
  type: RECEIVE_HEADSET_SERIES,
  items,
  receivedAt: Date.now(),
})

export const selectHeadsetSeries = (selectedHeadsetSeriesId) => (dispatch) => {
  dispatch({
    type: SELECT_HEADSET_SERIES,
    selectedHeadsetSeriesId,
  })
  return Promise.resolve()
}

export const fetchHeadsets =
  (headsetSeriesId = null) =>
  (dispatch) => {
    const url = headsetSeriesId
      ? `${API_URL}/headsets?headset_series_id=${headsetSeriesId}.json`
      : `${API_URL}/headsets`

    return fetch(url)
      .then(
        (response) => response.json(),
        (error) => console.log("An error occurred.", error),
      )
      .then((json) => dispatch(receiveHeadsets(json)))
  }

const receiveHeadsets = (items) => ({
  type: RECEIVE_HEADSETS,
  items,
  receivedAt: Date.now(),
})

export const selectHeadset = (selectedHeadsetId) => (dispatch) => {
  dispatch({
    type: SELECT_HEADSET,
    selectedHeadsetId,
  })
  return Promise.resolve()
}

export const fetchTopCables = () => (dispatch) => {
  return fetch(`${API_URL}/top_cables.json`)
    .then(
      (response) => response.json(),
      (error) => console.log("An error occurred.", error),
    )
    .then((json) => dispatch(receiveTopCables(json)))
}

const receiveTopCables = (items) => ({
  type: RECEIVE_TOP_CABLES,
  items,
  receivedAt: Date.now(),
})

export const selectTopCable = (selectedTopCableId) => (dispatch) => {
  dispatch({
    type: SELECT_TOP_CABLE,
    selectedTopCableId,
  })
  return Promise.resolve()
}

export const fetchPttSeries = () => (dispatch) => {
  return fetch(`${API_URL}/ptt_series.json`)
    .then(
      (response) => response.json(),
      (error) => console.log("An error occurred.", error),
    )
    .then((json) => dispatch(receivePttSeries(json)))
}

const receivePttSeries = (items) => ({
  type: RECEIVE_PTT_SERIES,
  items,
  receivedAt: Date.now(),
})

export const selectPttSeries = (selectedPttSeriesId) => (dispatch) => {
  dispatch({
    type: SELECT_PTT_SERIES,
    selectedPttSeriesId,
  })
  return Promise.resolve()
}

export const fetchPtts =
  (pttSeriesId = null) =>
  (dispatch) => {
    const url = pttSeriesId
      ? `${API_URL}/ptts?ptt_series_id=${pttSeriesId}.json`
      : `${API_URL}/ptts`

    return fetch(url)
      .then(
        (response) => response.json(),
        (error) => console.log("An error occurred.", error),
      )
      .then((json) => dispatch(receivePtts(json)))
  }

const receivePtts = (items) => ({
  type: RECEIVE_PTTS,
  items,
  receivedAt: Date.now(),
})

export const selectPtt = (selectedPttId) => (dispatch) => {
  dispatch({
    type: SELECT_PTT,
    selectedPttId,
  })
  return Promise.resolve()
}

export const fetchBottomCables = () => (dispatch) => {
  return fetch(`${API_URL}/bottom_cables.json`)
    .then(
      (response) => response.json(),
      (error) => console.log("An error occurred.", error),
    )
    .then((json) => dispatch(receiveBottomCables(json)))
}

const receiveBottomCables = (items) => ({
  type: RECEIVE_BOTTOM_CABLES,
  items,
  receivedAt: Date.now(),
})

export const selectBottomCable = (selectedBottomCableId) => (dispatch) => {
  dispatch({
    type: SELECT_BOTTOM_CABLE,
    selectedBottomCableId,
  })
  return Promise.resolve()
}

export const fetchCableWithoutPtts = () => (dispatch) => {
  return fetch(`${API_URL}/cable_without_ptts.json`)
    .then(
      (response) => response.json(),
      (error) => console.log("An error occurred.", error),
    )
    .then((json) => dispatch(receiveCableWithoutPtts(json)))
}

const receiveCableWithoutPtts = (items) => ({
  type: RECEIVE_CABLE_WITHOUT_PTTS,
  items,
  receivedAt: Date.now(),
})

export const selectCableWithoutPtt =
  (selectedCableWithoutPttId) => (dispatch) => {
    dispatch({
      type: SELECT_CABLE_WITHOUT_PTT,
      selectedCableWithoutPttId,
    })
    return Promise.resolve()
  }

export const fetchPlugs = () => (dispatch) => {
  return fetch(`${API_URL}/plugs.json`)
    .then(
      (response) => response.json(),
      (error) => console.log("An error occurred.", error),
    )
    .then((json) => dispatch(receivePlugs(json)))
}

const receivePlugs = (items) => ({
  type: RECEIVE_PLUGS,
  items,
  receivedAt: Date.now(),
})

export const selectPlug =
  (selectedPlugId, customSpecification = undefined) =>
  (dispatch) => {
    dispatch({
      type: SELECT_PLUG,
      selectedPlugId,
      customSpecification,
    })
    return Promise.resolve()
  }

export const fetchStepSkippers = () => (dispatch) => {
  return fetch(`${API_URL}/step_skippers.json`)
    .then(
      (response) => response.json(),
      (error) => console.log("An error occurred.", error),
    )
    .then((json) => dispatch(receiveStepSkippers(json)))
}

const receiveStepSkippers = (items) => ({
  type: RECEIVE_STEP_SKIPPERS,
  items,
  receivedAt: Date.now(),
})

export const fetchIncompatibilities = () => (dispatch) => {
  return fetch(`${API_URL}/incompatibilities.json`)
    .then(
      (response) => response.json(),
      (error) => console.log("An error occurred.", error),
    )
    .then((json) => dispatch(receiveIncompatibilities(json)))
}

const receiveIncompatibilities = (items) => ({
  type: RECEIVE_INCOMPATIBILITIES,
  items,
  receivedAt: Date.now(),
})

export const setPlugConfiguration = (plugConfiguration) => (dispatch) => {
  dispatch({
    type: SET_PLUG_CONFIGURATION,
    payload: plugConfiguration,
  })
  return Promise.resolve()
}

export const submitInquiry = (inquiry) => (dispatch) => {
  return fetch(`${API_URL}/inquiries.json`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      inquiry: inquiry,
    }),
  }).then((response) => {
    console.log("RESPONSE FROM SUBMIT INQUIRY", response)
    dispatch(inquirySubmitted(response))
  })
}

const inquirySubmitted = (response) => ({
  type: INQUIRY_SUBMITTED,
  response,
})

export const resetCart = () => (dispatch) => {
  dispatch({
    type: RESET_CART,
  })
  return Promise.resolve()
}

export const resetPlugConfiguration = () => (dispatch) => {
  dispatch({
    type: RESET_PLUG_CONFIGURATION,
  })
  return Promise.resolve()
}

export const updateCustomerDetails = (values) => (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMER_DETAILS,
    payload: values,
  })
}
export const resetCustomerDetails = () => (dispatch) => {
  dispatch({
    type: RESET_CUSTOMER_DETAILS,
  })
}
