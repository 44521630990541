import React from "react"
import "./style.scss"

export type Order = {
  name: string
  image_url: string
  title: string
  content: string
}
type OrderDetailsProps = {
  order: Order[]
}

const OrderDetails = ({ order }: OrderDetailsProps) => {

  const arrayOfItems: any = []

  const itemsToDisplay = (order: any) => {
    const headset = order.find(
      (item: { title: string }) => item.title === "Headset",
    )
    const ptt = order.find((item: { title: string }) => item.title === "PTT")

    if (headset) {
      if (headset.microphone_type === "electret") {
        arrayOfItems.push({
          title: "Microphone",
          subtitle: "Electret",
        })
      } else {
        arrayOfItems.push({
          title: "Microphone",
          subtitle: "Dynamic",
        })
      }
      if (headset.wearing_style === "single_sided") {
        arrayOfItems.push({
          title: "Headset Type",
          subtitle: "Single Sided",
        })
      } else {
        arrayOfItems.push({
          title: "Headset Type",
          subtitle: "Double Sided",
        })
      }

      if (headset.active_gard === "only_with") {
        arrayOfItems.push({
          title: "ActiveGard",
          subtitle: "With ActiveGard™",
        })
      } else if (headset.active_gard === "only_without") {
        arrayOfItems.push({
          title: "ActiveGard",
          subtitle: "Without ActiveGard™",
        })
      } else {
        arrayOfItems.push({
          title: "ActiveGard",
          subtitle: "With/ Without ActiveGard™",
        })
      }
    }

    if (ptt) {
      if (ptt.port_type === "usb") {
        arrayOfItems.push({
          title: "Interface",
          subtitle: "USB",
        })
      } else {
        arrayOfItems.push({
          title: "Interface",
          subtitle: "Analogue",
        })
      }
      if (ptt.microphone_preamplifier) {
        arrayOfItems.push({
          title: "Micrphone Preamplifier",
          subtitle: "With Preamplifier",
        })
      } else {
        arrayOfItems.push({
          title: "Micrphone Preamplifier",
          subtitle: "Without Preamplifier",
        })
      }

      if (ptt.plug_type === "direct") {
        arrayOfItems.push({
          title: "Connector Type",
          subtitle: "Direct",
        })
      } else {
        arrayOfItems.push({
          title: "Connector Type",
          subtitle: "TA6",
        })
      }
    }
  }
  itemsToDisplay(order)

  return (
    <div>
      <div className="order-details">
        {arrayOfItems.map((order: any) => (
          <div className="order-details__item" key={order.title}>
            <div className="order-details__item__title">{order.title}</div>

            <div className="order-details__item__description">
              {order.subtitle}
            </div>
          </div>
        ))}
        {/* {order.map((order: Order) => (
          <div className="order-details__item" key={order.title}>
            <div className="order-details__item__title">
              {order.title == "Plugs" ? "Connector Type" : order.title}
            </div>
            {order.title == "Plug Configuration" ? (
              <div className="order-details__item__description">
                {order.content}
              </div>
            ) : (
              <div className="order-details__item__description">
                {order.name}
              </div>
            )}
          </div>
        ))} */}
      </div>
    </div>
  )
}

export default OrderDetails
