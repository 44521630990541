export const getFooterArray = (cartObj: any) => {
  const footerArray: string[] = []

  const addNameToFooter = (name?: string) => {
    if (!name) return

    footerArray.push(name.substring(0, 15))
  }

  addNameToFooter(cartObj.headsetSeries.name)
  addNameToFooter(cartObj.headset.name)
  addNameToFooter(cartObj.pttSeries.name)
  addNameToFooter(cartObj.ptt.name)
  addNameToFooter(cartObj.topCable.name)
  addNameToFooter(cartObj.cableWithoutPtt.name)
  addNameToFooter(cartObj.bottomCable.name)
  addNameToFooter(cartObj.plug.name)
  // i removed the plug configuration from the footer, because it is not in design
  // addNameToFooter(cartObj.plugConfiguration.description)

  return footerArray
}
