export default [
  {
    id: "AFG",
    html: "Afghanistan",
  },
  {
    id: "ALA",
    html: "Åland Islands",
  },
  {
    id: "ALB",
    html: "Albania",
  },
  {
    id: "DZA",
    html: "Algeria",
  },
  {
    id: "ASM",
    html: "American Samoa",
  },
  {
    id: "AND",
    html: "Andorra",
  },
  {
    id: "AGO",
    html: "Angola",
  },
  {
    id: "AIA",
    html: "Anguilla",
  },
  {
    id: "ATA",
    html: "Antarctica",
  },
  {
    id: "ATG",
    html: "Antigua and Barbuda",
  },
  {
    id: "ARG",
    html: "Argentina",
  },
  {
    id: "ARM",
    html: "Armenia",
  },
  {
    id: "ABW",
    html: "Aruba",
  },
  {
    id: "AUS",
    html: "Australia",
  },
  {
    id: "AUT",
    html: "Austria",
  },
  {
    id: "AZE",
    html: "Azerbaijan",
  },
  {
    id: "BHS",
    html: "Bahamas",
  },
  {
    id: "BHR",
    html: "Bahrain",
  },
  {
    id: "BGD",
    html: "Bangladesh",
  },
  {
    id: "BRB",
    html: "Barbados",
  },
  {
    id: "BLR",
    html: "Belarus",
  },
  {
    id: "BEL",
    html: "Belgium",
  },
  {
    id: "BLZ",
    html: "Belize",
  },
  {
    id: "BEN",
    html: "Benin",
  },
  {
    id: "BMU",
    html: "Bermuda",
  },
  {
    id: "BTN",
    html: "Bhutan",
  },
  {
    id: "BOL",
    html: "Bolivia, Plurinational State of",
  },
  {
    id: "BES",
    html: "Bonaire, Sint Eustatius and Saba",
  },
  {
    id: "BIH",
    html: "Bosnia and Herzegovina",
  },
  {
    id: "BWA",
    html: "Botswana",
  },
  {
    id: "BVT",
    html: "Bouvet Island",
  },
  {
    id: "BRA",
    html: "Brazil",
  },
  {
    id: "IOT",
    html: "British Indian Ocean Territory",
  },
  {
    id: "BRN",
    html: "Brunei Darussalam",
  },
  {
    id: "BGR",
    html: "Bulgaria",
  },
  {
    id: "BFA",
    html: "Burkina Faso",
  },
  {
    id: "BDI",
    html: "Burundi",
  },
  {
    id: "KHM",
    html: "Cambodia",
  },
  {
    id: "CMR",
    html: "Cameroon",
  },
  {
    id: "CAN",
    html: "Canada",
  },
  {
    id: "CPV",
    html: "Cape Verde",
  },
  {
    id: "CYM",
    html: "Cayman Islands",
  },
  {
    id: "CAF",
    html: "Central African Republic",
  },
  {
    id: "TCD",
    html: "Chad",
  },
  {
    id: "CHL",
    html: "Chile",
  },
  {
    id: "CHN",
    html: "China",
  },
  {
    id: "CXR",
    html: "Christmas Island",
  },
  {
    id: "CCK",
    html: "Cocos (Keeling) Islands",
  },
  {
    id: "COL",
    html: "Colombia",
  },
  {
    id: "COM",
    html: "Comoros",
  },
  {
    id: "COG",
    html: "Congo",
  },
  {
    id: "COD",
    html: "Congo, the Democratic Republic of the",
  },
  {
    id: "COK",
    html: "Cook Islands",
  },
  {
    id: "CRI",
    html: "Costa Rica",
  },
  {
    id: "CIV",
    html: "Côte d'Ivoire",
  },
  {
    id: "HRV",
    html: "Croatia",
  },
  {
    id: "CUB",
    html: "Cuba",
  },
  {
    id: "CUW",
    html: "Curaçao",
  },
  {
    id: "CYP",
    html: "Cyprus",
  },
  {
    id: "CZE",
    html: "Czech Republic",
  },
  {
    id: "DNK",
    html: "Denmark",
  },
  {
    id: "DJI",
    html: "Djibouti",
  },
  {
    id: "DMA",
    html: "Dominica",
  },
  {
    id: "DOM",
    html: "Dominican Republic",
  },
  {
    id: "ECU",
    html: "Ecuador",
  },
  {
    id: "EGY",
    html: "Egypt",
  },
  {
    id: "SLV",
    html: "El Salvador",
  },
  {
    id: "GNQ",
    html: "Equatorial Guinea",
  },
  {
    id: "ERI",
    html: "Eritrea",
  },
  {
    id: "EST",
    html: "Estonia",
  },
  {
    id: "ETH",
    html: "Ethiopia",
  },
  {
    id: "FLK",
    html: "Falkland Islands (Malvinas)",
  },
  {
    id: "FRO",
    html: "Faroe Islands",
  },
  {
    id: "FJI",
    html: "Fiji",
  },
  {
    id: "FIN",
    html: "Finland",
  },
  {
    id: "FRA",
    html: "France",
  },
  {
    id: "GUF",
    html: "French Guiana",
  },
  {
    id: "PYF",
    html: "French Polynesia",
  },
  {
    id: "ATF",
    html: "French Southern Territories",
  },
  {
    id: "GAB",
    html: "Gabon",
  },
  {
    id: "GMB",
    html: "Gambia",
  },
  {
    id: "GEO",
    html: "Georgia",
  },
  {
    id: "DEU",
    html: "Germany",
  },
  {
    id: "GHA",
    html: "Ghana",
  },
  {
    id: "GIB",
    html: "Gibraltar",
  },
  {
    id: "GRC",
    html: "Greece",
  },
  {
    id: "GRL",
    html: "Greenland",
  },
  {
    id: "GRD",
    html: "Grenada",
  },
  {
    id: "GLP",
    html: "Guadeloupe",
  },
  {
    id: "GUM",
    html: "Guam",
  },
  {
    id: "GTM",
    html: "Guatemala",
  },
  {
    id: "GGY",
    html: "Guernsey",
  },
  {
    id: "GIN",
    html: "Guinea",
  },
  {
    id: "GNB",
    html: "Guinea-Bissau",
  },
  {
    id: "GUY",
    html: "Guyana",
  },
  {
    id: "HTI",
    html: "Haiti",
  },
  {
    id: "HMD",
    html: "Heard Island and McDonald Islands",
  },
  {
    id: "VAT",
    html: "Holy See (Vatican City State)",
  },
  {
    id: "HND",
    html: "Honduras",
  },
  {
    id: "HKG",
    html: "Hong Kong",
  },
  {
    id: "HUN",
    html: "Hungary",
  },
  {
    id: "ISL",
    html: "Iceland",
  },
  {
    id: "IND",
    html: "India",
  },
  {
    id: "IDN",
    html: "Indonesia",
  },
  {
    id: "IRN",
    html: "Iran, Islamic Republic of",
  },
  {
    id: "IRQ",
    html: "Iraq",
  },
  {
    id: "IRL",
    html: "Ireland",
  },
  {
    id: "IMN",
    html: "Isle of Man",
  },
  {
    id: "ITA",
    html: "Italy",
  },
  {
    id: "JAM",
    html: "Jamaica",
  },
  {
    id: "JPN",
    html: "Japan",
  },
  {
    id: "JEY",
    html: "Jersey",
  },
  {
    id: "JOR",
    html: "Jordan",
  },
  {
    id: "KAZ",
    html: "Kazakhstan",
  },
  {
    id: "KEN",
    html: "Kenya",
  },
  {
    id: "KIR",
    html: "Kiribati",
  },
  {
    id: "PRK",
    html: "Korea, Democratic People's Republic of",
  },
  {
    id: "KOR",
    html: "Korea, Republic of",
  },
  {
    id: "KWT",
    html: "Kuwait",
  },
  {
    id: "KGZ",
    html: "Kyrgyzstan",
  },
  {
    id: "LAO",
    html: "Lao People's Democratic Republic",
  },
  {
    id: "LVA",
    html: "Latvia",
  },
  {
    id: "LBN",
    html: "Lebanon",
  },
  {
    id: "LSO",
    html: "Lesotho",
  },
  {
    id: "LBR",
    html: "Liberia",
  },
  {
    id: "LBY",
    html: "Libya",
  },
  {
    id: "LIE",
    html: "Liechtenstein",
  },
  {
    id: "LTU",
    html: "Lithuania",
  },
  {
    id: "LUX",
    html: "Luxembourg",
  },
  {
    id: "MAC",
    html: "Macao",
  },
  {
    id: "MKD",
    html: "Macedonia, the former Yugoslav Republic of",
  },
  {
    id: "MDG",
    html: "Madagascar",
  },
  {
    id: "MWI",
    html: "Malawi",
  },
  {
    id: "MYS",
    html: "Malaysia",
  },
  {
    id: "MDV",
    html: "Maldives",
  },
  {
    id: "MLI",
    html: "Mali",
  },
  {
    id: "MLT",
    html: "Malta",
  },
  {
    id: "MHL",
    html: "Marshall Islands",
  },
  {
    id: "MTQ",
    html: "Martinique",
  },
  {
    id: "MRT",
    html: "Mauritania",
  },
  {
    id: "MUS",
    html: "Mauritius",
  },
  {
    id: "MYT",
    html: "Mayotte",
  },
  {
    id: "MEX",
    html: "Mexico",
  },
  {
    id: "FSM",
    html: "Micronesia, Federated States of",
  },
  {
    id: "MDA",
    html: "Moldova, Republic of",
  },
  {
    id: "MCO",
    html: "Monaco",
  },
  {
    id: "MNG",
    html: "Mongolia",
  },
  {
    id: "MNE",
    html: "Montenegro",
  },
  {
    id: "MSR",
    html: "Montserrat",
  },
  {
    id: "MAR",
    html: "Morocco",
  },
  {
    id: "MOZ",
    html: "Mozambique",
  },
  {
    id: "MMR",
    html: "Myanmar",
  },
  {
    id: "NAM",
    html: "Namibia",
  },
  {
    id: "NRU",
    html: "Nauru",
  },
  {
    id: "NPL",
    html: "Nepal",
  },
  {
    id: "NLD",
    html: "Netherlands",
  },
  {
    id: "NCL",
    html: "New Caledonia",
  },
  {
    id: "NZL",
    html: "New Zealand",
  },
  {
    id: "NIC",
    html: "Nicaragua",
  },
  {
    id: "NER",
    html: "Niger",
  },
  {
    id: "NGA",
    html: "Nigeria",
  },
  {
    id: "NIU",
    html: "Niue",
  },
  {
    id: "NFK",
    html: "Norfolk Island",
  },
  {
    id: "MNP",
    html: "Northern Mariana Islands",
  },
  {
    id: "NOR",
    html: "Norway",
  },
  {
    id: "OMN",
    html: "Oman",
  },
  {
    id: "PAK",
    html: "Pakistan",
  },
  {
    id: "PLW",
    html: "Palau",
  },
  {
    id: "PSE",
    html: "Palestinian Territory, Occupied",
  },
  {
    id: "PAN",
    html: "Panama",
  },
  {
    id: "PNG",
    html: "Papua New Guinea",
  },
  {
    id: "PRY",
    html: "Paraguay",
  },
  {
    id: "PER",
    html: "Peru",
  },
  {
    id: "PHL",
    html: "Philippines",
  },
  {
    id: "PCN",
    html: "Pitcairn",
  },
  {
    id: "POL",
    html: "Poland",
  },
  {
    id: "PRT",
    html: "Portugal",
  },
  {
    id: "PRI",
    html: "Puerto Rico",
  },
  {
    id: "QAT",
    html: "Qatar",
  },
  {
    id: "REU",
    html: "Réunion",
  },
  {
    id: "ROU",
    html: "Romania",
  },
  {
    id: "RUS",
    html: "Russian Federation",
  },
  {
    id: "RWA",
    html: "Rwanda",
  },
  {
    id: "BLM",
    html: "Saint Barthélemy",
  },
  {
    id: "SHN",
    html: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    id: "KNA",
    html: "Saint Kitts and Nevis",
  },
  {
    id: "LCA",
    html: "Saint Lucia",
  },
  {
    id: "MAF",
    html: "Saint Martin (French part)",
  },
  {
    id: "SPM",
    html: "Saint Pierre and Miquelon",
  },
  {
    id: "VCT",
    html: "Saint Vincent and the Grenadines",
  },
  {
    id: "WSM",
    html: "Samoa",
  },
  {
    id: "SMR",
    html: "San Marino",
  },
  {
    id: "STP",
    html: "Sao Tome and Principe",
  },
  {
    id: "SAU",
    html: "Saudi Arabia",
  },
  {
    id: "SEN",
    html: "Senegal",
  },
  {
    id: "SRB",
    html: "Serbia",
  },
  {
    id: "SYC",
    html: "Seychelles",
  },
  {
    id: "SLE",
    html: "Sierra Leone",
  },
  {
    id: "SGP",
    html: "Singapore",
  },
  {
    id: "SXM",
    html: "Sint Maarten (Dutch part)",
  },
  {
    id: "SVK",
    html: "Slovakia",
  },
  {
    id: "SVN",
    html: "Slovenia",
  },
  {
    id: "SLB",
    html: "Solomon Islands",
  },
  {
    id: "SOM",
    html: "Somalia",
  },
  {
    id: "ZAF",
    html: "South Africa",
  },
  {
    id: "SGS",
    html: "South Georgia and the South Sandwich Islands",
  },
  {
    id: "SSD",
    html: "South Sudan",
  },
  {
    id: "ESP",
    html: "Spain",
  },
  {
    id: "LKA",
    html: "Sri Lanka",
  },
  {
    id: "SDN",
    html: "Sudan",
  },
  {
    id: "SUR",
    html: "Suriname",
  },
  {
    id: "SJM",
    html: "Svalbard and Jan Mayen",
  },
  {
    id: "SWZ",
    html: "Swaziland",
  },
  {
    id: "SWE",
    html: "Sweden",
  },
  {
    id: "CHE",
    html: "Switzerland",
  },
  {
    id: "SYR",
    html: "Syrian Arab Republic",
  },
  {
    id: "TWN",
    html: "Taiwan, Province of China",
  },
  {
    id: "TJK",
    html: "Tajikistan",
  },
  {
    id: "TZA",
    html: "Tanzania, United Republic of",
  },
  {
    id: "THA",
    html: "Thailand",
  },
  {
    id: "TLS",
    html: "Timor-Leste",
  },
  {
    id: "TGO",
    html: "Togo",
  },
  {
    id: "TKL",
    html: "Tokelau",
  },
  {
    id: "TON",
    html: "Tonga",
  },
  {
    id: "TTO",
    html: "Trinidad and Tobago",
  },
  {
    id: "TUN",
    html: "Tunisia",
  },
  {
    id: "TUR",
    html: "Turkey",
  },
  {
    id: "TKM",
    html: "Turkmenistan",
  },
  {
    id: "TCA",
    html: "Turks and Caicos Islands",
  },
  {
    id: "TUV",
    html: "Tuvalu",
  },
  {
    id: "UGA",
    html: "Uganda",
  },
  {
    id: "UKR",
    html: "Ukraine",
  },
  {
    id: "ARE",
    html: "United Arab Emirates",
  },
  {
    id: "GBR",
    html: "United Kingdom",
  },
  {
    id: "USA",
    html: "United States",
  },
  {
    id: "UMI",
    html: "United States Minor Outlying Islands",
  },
  {
    id: "URY",
    html: "Uruguay",
  },
  {
    id: "UZB",
    html: "Uzbekistan",
  },
  {
    id: "VUT",
    html: "Vanuatu",
  },
  {
    id: "VEN",
    html: "Venezuela, Bolivarian Republic of",
  },
  {
    id: "VNM",
    html: "Viet Nam",
  },
  {
    id: "VGB",
    html: "Virgin Islands, British",
  },
  {
    id: "VIR",
    html: "Virgin Islands, U.S.",
  },
  {
    id: "WLF",
    html: "Wallis and Futuna",
  },
  {
    id: "ESH",
    html: "Western Sahara",
  },
  {
    id: "YEM",
    html: "Yemen",
  },
  {
    id: "ZMB",
    html: "Zambia",
  },
  {
    id: "ZWE",
    html: "Zimbabwe",
  },
]
