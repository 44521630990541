import { createSelector } from "reselect"
import plugImage from "images/ATC-Generic-Connector.png"
import { map, toPairs, join, merge, find } from "ramda"

const objToS = ([key, value]) => `* ${key}: ${value}`

const convertToCartItem = (plugConfiguration) => ({
  name: "",
  description: join("\n", map(objToS, toPairs(plugConfiguration))) || undefined,
  htmlDescription:
    join("<br>", map(objToS, toPairs(plugConfiguration))) || undefined,
  image_url: plugImage,
})

const addTitle = (title, obj) => merge(obj, { title })

const getHeadsetSeries = (state) => state.headsetSeries
const getHeadsets = (state) => state.headsets
const getTopCables = (state) => state.topCables
const getPttSeries = (state) => state.pttSeries
const getPtts = (state) => state.ptts
const getBottomCables = (state) => state.bottomCables
const getCableWithoutPtts = (state) => state.cableWithoutPtts
const getPlugs = (state) => state.plugs
const getCartIds = (state) => state.cartIds
const getPlugConfiguration = (state) => state.plugConfiguration

const findItem = (id, list) => find((item) => item.id == id, list)
const customPlugItem = (cartIds) => {
  return {
    name: "Custom Plug",
    description: cartIds.customPlugSpecification,
    // Missing custom plug image
  }
}

export const getCart = createSelector(
  [
    getHeadsetSeries,
    getHeadsets,
    getTopCables,
    getPttSeries,
    getPtts,
    getBottomCables,
    getCableWithoutPtts,
    getPlugs,
    getCartIds,
    getPlugConfiguration,
  ],
  (
    headsetSeries,
    headsets,
    topCables,
    pttSeries,
    ptts,
    bottomCables,
    cableWithoutPtts,
    plugs,
    cartIds,
    plugConfiguration,
  ) => ({
    headsetSeries: addTitle(
      "Headset Series",
      findItem(cartIds.headsetSeriesId, headsetSeries.items),
    ),
    headset: addTitle("Headset", findItem(cartIds.headsetId, headsets.items)),
    topCable: addTitle(
      "Top Cable",
      findItem(cartIds.topCableId, topCables.items),
    ),
    pttSeries: addTitle(
      "PTT Series",
      findItem(cartIds.pttSeriesId, pttSeries.items),
    ),
    ptt: addTitle("PTT", findItem(cartIds.pttId, ptts.items)),
    bottomCable: addTitle(
      "Bottom Cable",
      findItem(cartIds.bottomCableId, bottomCables.items),
    ),
    cableWithoutPtt: addTitle(
      "Cable without PTT",
      findItem(cartIds.cableWithoutPttId, cableWithoutPtts.items),
    ),
    plug:
      (cartIds.plugId &&
        addTitle("Plugs", findItem(cartIds.plugId, plugs.items))) ||
      (cartIds.customPlugSpecification &&
        addTitle("Custom Plug", customPlugItem(cartIds))) ||
      "",
    plugConfiguration: addTitle(
      "Plug Configuration",
      convertToCartItem(plugConfiguration),
    ),
  }),
)
