import React from "react"

type DropdownProps = {
  label?: string
  value?: string
  onChange: (option: Option) => void
  options: Option[]
  className?: string
  defaultName?: string
}

type Option = {
  label: string
  value: string
}

const Dropdown = ({
  label,
  value,
  onChange,
  options,
  className,
  defaultName,
}: DropdownProps) => {
  const onSelect = (e: any) => {
    let labelValue = e.target.value
    if (label) labelValue = label

    onChange({ label: labelValue, value: e.target.value })
  }
  return (
    <div className={className}>
      {label && <div className="mb-2">{label}</div>}
      <select
        className="border border-solid border-black w-full py-4 px-5"
        onChange={onSelect}
        value={value}
      >
        <option value="">{defaultName || "Choose variable"}</option>
        {options.map((option, index) => (
          <option key={index} value={option.label}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Dropdown
