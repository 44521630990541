import React, { Dispatch, SetStateAction } from "react"
import { isEmpty } from "ramda"

export type TopCable = {
  description: string
  id: number
  image_url: string
  name: string
}

type AddOnSelectProps = {
  onAddOnSelect: (addOnId: number) => void
  className: string
  addOn: TopCable[]
  selectedAddOn: number
  setSelectedAddOn: Dispatch<SetStateAction<number>>
  optionName: string
}

const AddOnSelect = (props: AddOnSelectProps) => {
  const {
    onAddOnSelect,
    className,
    addOn,
    selectedAddOn,
    setSelectedAddOn,
    optionName,
  } = props

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation()

    const { value } = e.target
    const addOnType = isEmpty(value) ? "" : value

    const foundAddOn = addOn.find((addOn) => addOn.id === parseInt(addOnType))

    if (!foundAddOn) return

    setSelectedAddOn(foundAddOn.id)
    onAddOnSelect(foundAddOn.id)
  }
  return (
    <div className={className}>
      <select
        className="gray bg-white w-100 br1 input-reset select-bg py-4 px-5"
        onChange={onChange}
        onClick={(e) => e.stopPropagation()}
        value={selectedAddOn}
      >
        <option value="">{optionName}</option>
        {addOn &&
          addOn.map((addOn) => (
            <option key={addOn.id} value={addOn.id}>
              {addOn.name}
            </option>
          ))}
      </select>
    </div>
  )
}

export default AddOnSelect
