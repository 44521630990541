import React from "react"
import AccessoryInfo from "../AccessoryInfo"

type Props = {
  id: number
  name: string
  microphone_preamplifier: boolean | null | boolean[]
  plug_type: string | null | string[]
  port_type: string | null | string[]
  image_url: string
  onSelect?: (id: number) => void
  series?: boolean
  className?: string
  description?: string
}

const PttCard = (props: Props) => {
  const {
    id,
    name,
    microphone_preamplifier,
    plug_type,
    port_type,
    image_url,
    onSelect,
    series,
    className,
    description,
  } = props

  const transformPreamplifier = (
    microphone_preamplifier: boolean | boolean[] | null,
  ) => {
    if (microphone_preamplifier == null) return "-"
    if (microphone_preamplifier === false) {
      return "Without Preamplifier"
    }
    if (microphone_preamplifier === true) {
      return "With Preamplifier"
    }
    if (Array.isArray(microphone_preamplifier)) {
      if (microphone_preamplifier.length > 1) {
        return "With Preamplifier | Without Preamplifier"
      } else if (microphone_preamplifier[0] === true) {
        return "With Preamplifier"
      } else if (microphone_preamplifier[0] === false) {
        return "Without Preamplifier"
      } else {
        return "-"
      }
    }
    return "-"
  }

  const transformPlugOrPort = (accessory: string | string[] | null) => {
    if (accessory == null || accessory.length == 0 || accessory[0] == null)
      return "-"
    if (typeof accessory === "string") {
      if (accessory === "analogue" || accessory === "direct") {
        return accessory[0].toUpperCase() + accessory.slice(1)
      }
      return accessory.toUpperCase()
    }
    if (Array.isArray(accessory)) {
      if (accessory.length === 0) return "-"
      if (accessory.length > 1 && accessory.includes("analogue")) {
        return "Analogue | USB"
      } else if (accessory.length > 1 && accessory.includes("direct")) {
        return "Direct | TA6"
      }
      if (accessory[0] === "analogue" || accessory[0] === "direct") {
        return accessory[0][0].toUpperCase() + accessory[0].slice(1)
      } else {
        return accessory[0].toUpperCase()
      }
    }
    return "-"
  }

  const listOfAccessories = [
    {
      accessoryType: "Interface",
      accessoryName: transformPlugOrPort(port_type),
    },
    {
      accessoryType: "Microphone Preamplifier",
      accessoryName: transformPreamplifier(microphone_preamplifier),
    },
    {
      accessoryType: "Connector Type",
      accessoryName: transformPlugOrPort(plug_type),
    },
  ]

  return (
    <article
      className={`group flex flex-col w-full border-b-2 border-slate-100 ${className}`}
      onClick={onSelect ? () => onSelect(id) : undefined}
    >
      <div className="relative flex justify-center h-96 bg-primaryGray">
        {onSelect !== undefined && (
          <div className="group-hover:bg-hoverGreen opacity-80 absolute top-0 left-0 h-full w-full flex justify-center items-center">
            <p className="opacity-0 group-hover:opacity-100 text-white">
              Choose and continue
            </p>
          </div>
        )}
        <img src={image_url} alt={name} className="p-2 max-h-full" />
      </div>
      <div className="py-5 lg:py-10">
        <p className="text-lg font-semibold mb-2 lg:mb-4">
          {series ? `${name} Series` : name}
        </p>
        {!description && (
          <pre className="font-sans text-sm mb-6 lg:mb-12">{description}</pre>
        )}
        <div className="flex flex-col gap-2 text-sm">
          {listOfAccessories.map((accessory) => (
            <AccessoryInfo {...accessory} key={id + accessory.accessoryType} />
          ))}
        </div>
      </div>
    </article>
  )
}

export default PttCard
