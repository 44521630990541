import React, { useMemo, useState } from "react"
import AddOnSelect, { TopCable } from "../AddOnSelect"
import AccessoryInfo from "../AccessoryInfo"
import attentionImage from "images/attention.svg"
import { connect } from "react-redux"
import { filterIncompatibleTopCables } from "../../helpers/IncompatibilityHelper"

type Props = {
  id: number
  name: string
  microphone_preamplifier: boolean | null | boolean[]
  plug_type: string | null | string[]
  port_type: string | null | string[]
  image_url: string
  onSelect?: (pttId: number, topCableId: number) => void
  series?: boolean
  className?: string
  description?: string
  topCables: TopCable[]
  incompatibilities: any
  cartIds: number[]
  addOnName: string
}

const PttCardWithDropdown = (props: Props) => {
  const {
    id,
    name,
    microphone_preamplifier,
    plug_type,
    port_type,
    image_url,
    onSelect,
    series,
    className,
    description,
    topCables,
    incompatibilities,
    cartIds,
    addOnName,
  } = props

  const [selectedCable, setSelectedCable] = useState<number>(0)

  const handleCableSelect = (topCableId: number) => {
    onSelect && onSelect(id, topCableId)
  }

  const compatibleTopCables = useMemo(() => {

    const filtered = topCables.filter((topCable: any) => {
      const incompatibleTopCables = filterIncompatibleTopCables(
        topCables,
        incompatibilities,
        // Pass in this card's pttId to check its compatibility with the top cables
        { ...cartIds, pttId: id },
      )

      const isCompatible =
        incompatibleTopCables.find(
          (incompatibleTopCable: any) =>
            incompatibleTopCable.id === topCable.id,
          // We did not find any incompatibility
        ) === undefined

      return isCompatible

    })
    return filtered
  }, [
    JSON.stringify(topCables),
    JSON.stringify(incompatibilities),
    JSON.stringify(cartIds),
  ])

  const transformPreamplifier = (
    microphone_preamplifier: boolean | boolean[] | null,
  ) => {
    if (microphone_preamplifier == null) return "-"
    if (microphone_preamplifier === false) {
      return "Without Preamplifier"
    }
    if (microphone_preamplifier === true) {
      return "With Preamplifier"
    }
    if (Array.isArray(microphone_preamplifier)) {
      if (microphone_preamplifier.length > 1) {
        return "With Preamplifier | Without Preamplifier"
      } else if (microphone_preamplifier[0] === true) {
        return "With Preamplifier"
      } else if (microphone_preamplifier[0] === false) {
        return "Without Preamplifier"
      } else {
        return "-"
      }
    }
    return "-"
  }

  const transformPlugOrPort = (accessory: string | string[] | null) => {
    if (accessory == null) return "-"
    if (typeof accessory === "string") {
      if (accessory === "analogue" || accessory === "direct") {
        return accessory[0].toUpperCase() + accessory.slice(1)
      }
      return accessory.toUpperCase()
    }
    if (Array.isArray(accessory)) {
      if (accessory.length === 0) return "-"
      if (accessory.length > 1 && accessory.includes("analogue")) {
        return "Analogue | USB"
      } else if (accessory.length > 1 && accessory.includes("direct")) {
        return "Direct | TA6"
      }
      if (accessory[0] === "analogue" || accessory[0] === "direct") {
        return accessory[0][0].toUpperCase() + accessory[0].slice(1)
      } else {
        return accessory[0].toUpperCase()
      }
    }
    return "-"
  }

  const listOfAccessories = [
    {
      accessoryType: "Interface",
      accessoryName: transformPlugOrPort(port_type),
    },
    {
      accessoryType: "Microphone Preamplifier",
      accessoryName: transformPreamplifier(microphone_preamplifier),
    },
    {
      accessoryType: "Connector Type",
      accessoryName: transformPlugOrPort(plug_type),
    },
  ]

  return (
    <article
      className={`group flex flex-col w-full border-b-2 border-slate-100 ${className} cursor-pointer`}
    >
      <div className="relative flex items-center justify-center h-96 bg-primaryGray">
        {onSelect !== undefined && (
          <div className="group-hover:bg-hoverGreen opacity-80 absolute top-0 left-0 h-full w-full flex flex-col gap-4 justify-center items-center">
            <p className="opacity-0 group-hover:opacity-100 text-white flex items-center gap-2 px-4">
              <img src={attentionImage} className="w-14" />
              <span>Select a {addOnName} from the dropdown below</span>
            </p>
          </div>
        )}
        <img src={image_url} alt={name} className="p-2 max-h-full" />
      </div>
      <div className="py-5">
        <p className="text-lg font-semibold mb-2 lg:mb-4">
          {series ? `${name} Series` : name}
        </p>
        {!series && (
          <pre className="font-sans font-light text-sm mb-4">{description}</pre>
        )}
        <div className="flex flex-col gap-2 text-sm mb-5">
          {listOfAccessories.map((accessory) => (
            <AccessoryInfo {...accessory} key={id + accessory.accessoryType} />
          ))}
        </div>
      </div>
      <AddOnSelect
        addOn={compatibleTopCables}
        className="cable-select mb-5"
        onAddOnSelect={handleCableSelect}
        selectedAddOn={selectedCable}
        setSelectedAddOn={setSelectedCable}
        optionName="Choose top cable"
      />
    </article>
  )
}

const mapStateToProps = (state: any) => ({
  topCables: state.topCables.items,
  cartIds: state.cartIds,
  incompatibilities: state.incompatibilities.items,
})

export default connect(mapStateToProps)(PttCardWithDropdown)
