import React from "react"

type Props = {
  accessoryType: string
  accessoryName: string
}

const AccessoryInfo = ({ accessoryType, accessoryName }: Props) => {
  return (
    <p className="flex justify-between gap-5">
      <span>{accessoryType}</span>
      <span className="text-right font-light">{accessoryName}</span>
    </p>
  )
}

export default AccessoryInfo
