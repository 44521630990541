import React from "react"
import PropTypes from "prop-types"
import { PrimaryButton } from "./Buttons"
import countries from "../data/countries"
import { useForm } from "react-hook-form"
import { updateCustomerDetails } from "../actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

const CustomerDetailsModal = ({
  onClose,
  show,
  customerDetails,
  updateCustomerDetails,
  onModalSubmit,
}) => {
  const { register, handleSubmit } = useForm({ defaultValues: customerDetails })

  const onSubmit = (data) => {
    onModalSubmit?.(data)
  }

  const onChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    updateCustomerDetails({ [name]: value })
  }

  return (
    <div
      className={
        "w-100 h-100 fixed bg-black-10 top-0 flex justify-center items-center " +
        (show ? "" : "hidden")
      }
    >
      <div onClick={onClose} className="absolute w-100 h-100"></div>
      <div className="relative mw7 w-100 h-75 bg-white overflow-auto right-0 left-0 ">
        <div
          onClick={onClose}
          className="absolute right-1 top-1 silver pointer"
        >
          X
        </div>
        <div className="pa5">
          <div className="f3 sh-new b mb4">Your details</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Field
              input={{ ...register("name", { required: true, onChange }) }}
              type="text"
              name="name"
              placeholder="Name"
              wrapperClass="db mv3"
              className="pa3 h3 w-100"
            />
            <Field
              input={{
                ...register("companyName", { required: true, onChange }),
              }}
              type="text"
              name="companyName"
              placeholder="Company Name"
              wrapperClass="mv3 db"
              className="h3 pa3 w-100"
            />
            <div className="flex mv3">
              <Field
                input={{ ...register("address", { required: true, onChange }) }}
                type="text"
                name="address"
                placeholder="Address"
                wrapperClass="dib flex-auto mr2"
                className="h3 pa3 w-100"
              />
              <Field
                input={{ ...register("zipCode", { required: true, onChange }) }}
                type="text"
                name="zipCode"
                placeholder="Zip Code"
                wrapperClass="dib flex-auto ml2"
                className="h3 pa3 w-100"
              />
            </div>
            <div className="flex mv3">
              <Field
                input={{ ...register("city", { required: true, onChange }) }}
                type="text"
                name="city"
                placeholder="City"
                wrapperClass="dib flex-auto w-50 mr2"
                className="h3 pa3 dib w-100"
              />
              <CountrySelect
                input={{ ...register("country", { required: true, onChange }) }}
                type="text"
                name="country"
                placeholder="-- Select country --"
                wrapperClass="dib flex-auto w-50 ml2"
                className="h3 pa3 dib w-100 bg-white"
              />
            </div>
            <div className="flex mv3">
              <Field
                input={{
                  ...register("phoneNumber", { required: true, onChange }),
                }}
                type="input"
                name="phoneNumber"
                placeholder="Phone Number"
                wrapperClass="dib flex-auto mr2"
                className="h3 pa3 w-100 dib"
              />
              <Field
                input={{ ...register("email", { required: true, onChange }) }}
                type="input"
                name="email"
                placeholder="Email"
                wrapperClass="dib flex-auto ml2"
                className="h3 pa3 w-100 dib"
              />
            </div>
            <Field
              input={{
                ...register("totalAmount", { required: true, onChange }),
              }}
              type="input"
              name="totalAmount"
              placeholder="Estimated annual quantity"
              className="mv3 h3 pa3 w-100 db"
            />
            <TextAreaField
              input={{ ...register("comments", { required: false, onChange }) }}
              name="comments"
              placeholder="Comments..."
              className="mv3 h3 pa3 w-100 db h4"
            />
            <PrimaryButton type="submit" className="mt4" text="Place Inquiry" />
          </form>
        </div>
      </div>
    </div>
  )
}

const Field = ({ input, placeholder, wrapperClass, className, type }) => (
  <div className={wrapperClass}>
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      className={className}
    />
  </div>
)

const TextAreaField = ({
  input,
  placeholder,
  wrapperClass,
  className,
  type,
}) => (
  <div className={wrapperClass}>
    <textarea
      {...input}
      placeholder={placeholder}
      type={type}
      className={className}
    />
  </div>
)

const CountrySelect = ({ input, placeholder, wrapperClass, className }) => (
  <div className={wrapperClass}>
    <select {...input} className={className}>
      <option value=""> {placeholder} </option>
      {countries.map((country) => {
        return (
          <option key={country["id"]} value={country["id"]}>
            {country["html"]}
          </option>
        )
      })}
    </select>
  </div>
)

CustomerDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  customerDetails: PropTypes.shape(),
  updateCustomerDetails: PropTypes.func,
  onModalSubmit: PropTypes.func,
}

const mapStateToProps = (state) => ({ customerDetails: state.customerDetails })

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateCustomerDetails }, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerDetailsModal)
